export const primaryMenu = [


   {
      name: "Atharva",
      label: "Aharva",
      path: "/about-us",
      child: [
         {
            path: '/about-us',
            child: [
               {
                  title: 'Introduction',
                  path: '/about-us'
               }
            ]
         },
         {
            path: '/team',
            child: [
               {
                  title: 'Vision, Mission, Goals & Objectives',
                  path: '/about-us'
               }
            ]
         },
         {
            path: '/messages',
            child: [
               {
                  title: 'Message from the Principal',
                  path: '/messages'
               }
            ]
         }
         //,
         // {
         //    path: '/bod',
         //    child: [
         //       {
         //          title: 'BOD (Board of Directors)',
         //          path: '/bod'
         //       }
         //    ]
         // }
         ,
         {
            path: '/team',
            child: [
               {
                  title: 'Management Team',
                  path: '/team'
               }
            ]
         }
         ,
         {
            path: '/alumni',
            child: [
               {
                  title: 'Atharva Former Students Council',
                  path: '/alumni'
               }
            ]
         }
      ]

   },
   {
      name: "Academic Programs",
      label: "Programs",
      path: "/courses",
      child: [
         {
            title: "BBA",
            path: '/course/bachelor-of-business-administration',
            child: [
               {
                  title: "BBA",
                  path: '/course/bachelor-of-business-administration',

               }
            ]

         },
         {
            title: "BHM",
            path: '/course/bachelor-of-hotel-management',
            child: [
               {
                  title: "BHM",
                  path: '/course/bachelor-of-hotel-management',

               }
            ]

         }
         ,
         {
            title: "BHCM",
            path: '/course/bachelor-of-health-care-management-1',
            child: [
               {
                  title: "BHCM",
                  path: '/course/bachelor-of-health-care-management-1',

               }
            ]

         },

         {
            title: "MBA",
            path: '/course/master-of-business-administration',
            child: [
               {
                  title: "MBA",
                  path: '/course/master-of-business-administration',

               }
            ]

         },
         {
            title: "MHCM",
            path: '/course/mhcm',
            child: [
               {
                  title: "MHCM",
                  path: '/course/mhcm',

               }
            ]

         }
         //  ,
         //  {
         //     title: "Short Term Courses",
         //     path: '/course/details',
         //     child: [
         //        {
         //           title: "Technician",
         //           path: '/course/details',

         //       },
         //       {
         //          title: "Computer Operator",
         //          path: '/course/details',

         //      }
         //     ]

         //  }

      ]
   },
   {
      name: "Admission",
      label: "Admission",
      path: "/admission",
   },
   {
      name: "Students Speak",
      label: "Students Speak",
      path: "/student-voice"
   },
   {
      name: "News & Stories",
      label: "News & Stories",
      path: "/news-events"
   },
   {
      name: "Events",
      label: "Events",
      path: "/events"
   },
   {
      name: "Research",
      label: "Research",
      // path: "/downloads",
      child: [
         {
            title: "Research Committee",
            label: "Research Committee",
            path: '/page/research-committee',
            child: [
               {
                  title: "Research Committee",
                  label: "Research Committee",
                  path: '/page/research-committee',

               }
            ]

         },
         {
            title: "Research Management Cell",
            label: "Research Management Cell",
            path: '/page/research-management-cell',
            child: [
               {
                  title: "Research Management Cell",
                  label: "Research Management Cell",
                  path: '/page/research-management-cell',

               }
            ]

         },
         {
            title: "Research Publication",
            label: "Research Publication",
            path: '/page/research-publication',
            child: [
               {
                  title: "Research Publication",
                  label: "Research Publication",
                  path: '/page/research-publication',
                  child: [
                     {
                        title: "Internal Publication",
                        label: "Internal Publication",
                        path: '/page/internal-publication',

                     }, {
                        title: "External Publication",
                        label: "External Publication",
                        path: '/page/external-publication',

                     }
                  ]

               }
            ]

         },
         {
            title: "Research Activities",
            label: "Research Activities",
            path: '/page/research-activities',
            child: [
               {
                  title: "Research Activities",
                  label: "Research Activities",
                  path: '/page/research-activities',

               }
            ]

         },
      ]
   },

   {
      name: "Get In Touch",
      label: "Get In Touch",
      path: "/contact-us"
   },
   {
      name: "Career",
      label: "request",
      path: "/request",
      type: 'button'
   }
]


export const address = [
   {
      "id": 1,
      "name": "Nepal",
      "is_active": 1,
      "created_at": null,
      "updated_at": null,
      "deleted_at": null,
      "provinces": [
         {
            "id": 1,
            "country_id": 1,
            "name": "State 1",
            "is_active": 1,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "districts": [
               {
                  "id": 1,
                  "province_id": 1,
                  "name": "Bhojpur",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 59,
                        "district_id": 1,
                        "name": "Arun village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 60,
                        "district_id": 1,
                        "name": "Amchok village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 61,
                        "district_id": 1,
                        "name": "Taikemaum village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 62,
                        "district_id": 1,
                        "name": "Powadungma Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 63,
                        "district_id": 1,
                        "name": "Bhojpur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 64,
                        "district_id": 1,
                        "name": "Ram Prasad Rai Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 65,
                        "district_id": 1,
                        "name": "Khadanannd Municipal",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 66,
                        "district_id": 1,
                        "name": "Salpasilichho Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 67,
                        "district_id": 1,
                        "name": "Hatuwagadhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 2,
                  "province_id": 1,
                  "name": "Dhankutta",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 68,
                        "district_id": 2,
                        "name": "Khilsa Chantang sahidbhumi Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 69,
                        "district_id": 2,
                        "name": "Chawisey village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 70,
                        "district_id": 2,
                        "name": "Chhathar Jorpativillage",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 71,
                        "district_id": 2,
                        "name": "Dhanukuta Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 72,
                        "district_id": 2,
                        "name": "Pakhribas Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 73,
                        "district_id": 2,
                        "name": "Mahalakshmi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 74,
                        "district_id": 2,
                        "name": "Sagurigadi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 3,
                  "province_id": 1,
                  "name": "Ilam",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 18,
                        "district_id": 3,
                        "name": "Ilam Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 19,
                        "district_id": 3,
                        "name": "Chulachuli village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 20,
                        "district_id": 3,
                        "name": "Deumai Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 21,
                        "district_id": 3,
                        "name": "Fakfokthum Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 22,
                        "district_id": 3,
                        "name": "Mai Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 23,
                        "district_id": 3,
                        "name": "Maijogami village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 24,
                        "district_id": 3,
                        "name": "Mangsebung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 25,
                        "district_id": 3,
                        "name": "Rong village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 26,
                        "district_id": 3,
                        "name": "Sandakapur villagepali",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 27,
                        "district_id": 3,
                        "name": "Suryodya municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 4,
                  "province_id": 1,
                  "name": "Jhapa",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 28,
                        "district_id": 4,
                        "name": "Arjundhara Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 29,
                        "district_id": 4,
                        "name": "Kachankabal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 30,
                        "district_id": 4,
                        "name": "Kanki Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 31,
                        "district_id": 4,
                        "name": "Kamal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 32,
                        "district_id": 4,
                        "name": "Gauradah Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 33,
                        "district_id": 4,
                        "name": "Gauriganj Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 34,
                        "district_id": 4,
                        "name": "Jhapa Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 35,
                        "district_id": 4,
                        "name": "Damak Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 36,
                        "district_id": 4,
                        "name": "Brahamdashi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 37,
                        "district_id": 4,
                        "name": "Buddha Shanti village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 38,
                        "district_id": 4,
                        "name": "Bhadrapur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 39,
                        "district_id": 4,
                        "name": "Mechi Municipal Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 40,
                        "district_id": 4,
                        "name": "Birtamod Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 41,
                        "district_id": 4,
                        "name": "Shivasatachhi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 42,
                        "district_id": 4,
                        "name": "Haldibaari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 5,
                  "province_id": 1,
                  "name": "Khotang",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 112,
                        "district_id": 5,
                        "name": "Aaiselukharka village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 113,
                        "district_id": 5,
                        "name": "Kopilashgadhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 114,
                        "district_id": 5,
                        "name": "Khotahang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 115,
                        "district_id": 5,
                        "name": "Born village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 116,
                        "district_id": 5,
                        "name": "Deeprung village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 117,
                        "district_id": 5,
                        "name": "Barahpokhari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 118,
                        "district_id": 5,
                        "name": "Rupapkot Mazuwagadhi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 119,
                        "district_id": 5,
                        "name": "Lamindada village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 120,
                        "district_id": 5,
                        "name": "Sacella Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 121,
                        "district_id": 5,
                        "name": "Halesi Tuwachung Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 6,
                  "province_id": 1,
                  "name": "Morang",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 75,
                        "district_id": 6,
                        "name": "Urlawari municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 76,
                        "district_id": 6,
                        "name": "Katahari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 77,
                        "district_id": 6,
                        "name": "Kanepokari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 78,
                        "district_id": 6,
                        "name": "Kerabaari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 79,
                        "district_id": 6,
                        "name": "Gramthan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 80,
                        "district_id": 6,
                        "name": "Zahada village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 81,
                        "district_id": 6,
                        "name": "Dhanpalthan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 82,
                        "district_id": 6,
                        "name": "Pathari Sanshare Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 83,
                        "district_id": 6,
                        "name": "Buddhiganga village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 84,
                        "district_id": 6,
                        "name": "Belwari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 85,
                        "district_id": 6,
                        "name": "Miklajung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 86,
                        "district_id": 6,
                        "name": "Rangeli Municipal",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 87,
                        "district_id": 6,
                        "name": "Ratuwamai Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 88,
                        "district_id": 6,
                        "name": "Lettang Municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 89,
                        "district_id": 6,
                        "name": "Biratnagar Metropolitan Police",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 90,
                        "district_id": 6,
                        "name": "Sunbarsha municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 91,
                        "district_id": 6,
                        "name": "Sundarharaicha municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 7,
                  "province_id": 1,
                  "name": "Okhaldhunga",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 122,
                        "district_id": 7,
                        "name": "Khajidemwa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 123,
                        "district_id": 7,
                        "name": "Champadevi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 124,
                        "district_id": 7,
                        "name": "Chinsakhugadhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 125,
                        "district_id": 7,
                        "name": "Manevanjyang Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 126,
                        "district_id": 7,
                        "name": "Molung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 127,
                        "district_id": 7,
                        "name": "Likhu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 128,
                        "district_id": 7,
                        "name": "Sidicharan Nagarpalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 129,
                        "district_id": 7,
                        "name": "Sunkoshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 8,
                  "province_id": 1,
                  "name": "Panchthar",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 10,
                        "district_id": 8,
                        "name": "Kumamak village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 11,
                        "district_id": 8,
                        "name": "Tumbewa Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 12,
                        "district_id": 8,
                        "name": "Fallelung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 13,
                        "district_id": 8,
                        "name": "Falgunand village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 14,
                        "district_id": 8,
                        "name": "Fiddimm Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 15,
                        "district_id": 8,
                        "name": "Miklajung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 16,
                        "district_id": 8,
                        "name": "Yangwarak village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 17,
                        "district_id": 8,
                        "name": "Hillhang Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 9,
                  "province_id": 1,
                  "name": "Sankhuwasabha",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 43,
                        "district_id": 9,
                        "name": "Khandwari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 44,
                        "district_id": 9,
                        "name": "Chichilla village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 45,
                        "district_id": 9,
                        "name": "Chainpur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 46,
                        "district_id": 9,
                        "name": "Dharmadevi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 47,
                        "district_id": 9,
                        "name": "Panchkhapan municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 48,
                        "district_id": 9,
                        "name": "Bhotkhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 49,
                        "district_id": 9,
                        "name": "Makalu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 50,
                        "district_id": 9,
                        "name": "Madi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 51,
                        "district_id": 9,
                        "name": "SabhaPokhari Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 52,
                        "district_id": 9,
                        "name": "Silichong Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 10,
                  "province_id": 1,
                  "name": "Solukhumbu",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 104,
                        "district_id": 10,
                        "name": "Khumvas Pasanglahmu Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 105,
                        "district_id": 10,
                        "name": "Dudhodhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 106,
                        "district_id": 10,
                        "name": "Doodhkoushika village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 107,
                        "district_id": 10,
                        "name": "Nachasalyan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 108,
                        "district_id": 10,
                        "name": "Mahakulung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 109,
                        "district_id": 10,
                        "name": "Likhupike Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 110,
                        "district_id": 10,
                        "name": "Sottang Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 111,
                        "district_id": 10,
                        "name": "Solu Dudhkund Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 11,
                  "province_id": 1,
                  "name": "Sunsari",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 92,
                        "district_id": 11,
                        "name": "Itahari subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 93,
                        "district_id": 11,
                        "name": "Inneruwa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 94,
                        "district_id": 11,
                        "name": "Koshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 95,
                        "district_id": 11,
                        "name": "Garhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 96,
                        "district_id": 11,
                        "name": "Duhabi Municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 97,
                        "district_id": 11,
                        "name": "Daawanganj village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 98,
                        "district_id": 11,
                        "name": "Dharan subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 99,
                        "district_id": 11,
                        "name": "Barah Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 100,
                        "district_id": 11,
                        "name": "Bargu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 101,
                        "district_id": 11,
                        "name": "Bhokraha village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 102,
                        "district_id": 11,
                        "name": "Ramadhuni Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 103,
                        "district_id": 11,
                        "name": "HariNagara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 12,
                  "province_id": 1,
                  "name": "Taplejung",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 1,
                        "district_id": 12,
                        "name": "Atthrai Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 2,
                        "district_id": 12,
                        "name": "Faktanglung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 3,
                        "district_id": 12,
                        "name": "Fungling Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 4,
                        "district_id": 12,
                        "name": "Mikwakhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 5,
                        "district_id": 12,
                        "name": "Meringden village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 6,
                        "district_id": 12,
                        "name": "Mauwakhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 7,
                        "district_id": 12,
                        "name": "Yangwarak village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 8,
                        "district_id": 12,
                        "name": "Sidingwa Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 9,
                        "district_id": 12,
                        "name": "Seriesjungha Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 13,
                  "province_id": 1,
                  "name": "Terhathum",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 53,
                        "district_id": 13,
                        "name": "attharai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 54,
                        "district_id": 13,
                        "name": "Chhattar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 55,
                        "district_id": 13,
                        "name": "Fedap village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 56,
                        "district_id": 13,
                        "name": "Menchayem village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 57,
                        "district_id": 13,
                        "name": "Mayanglung Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 58,
                        "district_id": 13,
                        "name": "Laliguniras Municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 14,
                  "province_id": 1,
                  "name": "Updaypur",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 130,
                        "district_id": 14,
                        "name": "Udaipur Gaddhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 131,
                        "district_id": 14,
                        "name": "Katari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 132,
                        "district_id": 14,
                        "name": "Chudandigadi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 133,
                        "district_id": 14,
                        "name": "Tapli village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 134,
                        "district_id": 14,
                        "name": "Tiriyuga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 135,
                        "district_id": 14,
                        "name": "Rautamai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 136,
                        "district_id": 14,
                        "name": "Bellka Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 137,
                        "district_id": 14,
                        "name": "Sunkoshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               }
            ]
         },
         {
            "id": 2,
            "country_id": 1,
            "name": "State 2",
            "is_active": 1,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "districts": [
               {
                  "id": 15,
                  "province_id": 2,
                  "name": "Bara",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 237,
                        "district_id": 15,
                        "name": "Aadrsha Kautwal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 238,
                        "district_id": 15,
                        "name": "Kariyamai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 239,
                        "district_id": 15,
                        "name": "Kaliya Subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 240,
                        "district_id": 15,
                        "name": "Kolhvi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 241,
                        "district_id": 15,
                        "name": "Jeeppur Samimara Subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 242,
                        "district_id": 15,
                        "name": "Devtal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 243,
                        "district_id": 15,
                        "name": "Nijgarh Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 244,
                        "district_id": 15,
                        "name": "Panchrauta village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 245,
                        "district_id": 15,
                        "name": "Parvaniapur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 246,
                        "district_id": 15,
                        "name": "Parsauni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 247,
                        "district_id": 15,
                        "name": "Feta Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 248,
                        "district_id": 15,
                        "name": "Baragadi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 249,
                        "district_id": 15,
                        "name": "Mahagadhimai Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 250,
                        "district_id": 15,
                        "name": "Simimrongarh Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 251,
                        "district_id": 15,
                        "name": "Subaurna village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 16,
                  "province_id": 2,
                  "name": "Dhanusha",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 172,
                        "district_id": 16,
                        "name": "Aurahi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 173,
                        "district_id": 16,
                        "name": "Kamla Siddihri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 174,
                        "district_id": 16,
                        "name": "Kshiireshwarnath Nagarpalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 175,
                        "district_id": 16,
                        "name": "Ganeshman Chararnath Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 176,
                        "district_id": 16,
                        "name": "Jankanandini village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 177,
                        "district_id": 16,
                        "name": "Janakpur Subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 178,
                        "district_id": 16,
                        "name": "Dhanunhham Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 179,
                        "district_id": 16,
                        "name": "Nagarine municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 180,
                        "district_id": 16,
                        "name": "Bateswor village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 181,
                        "district_id": 16,
                        "name": "Mithila Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 182,
                        "district_id": 16,
                        "name": "Mithila Bihari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 183,
                        "district_id": 16,
                        "name": "Mudipattati Mosharamiya village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 184,
                        "district_id": 16,
                        "name": "Lakshminia Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 185,
                        "district_id": 16,
                        "name": "Bideh Municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 186,
                        "district_id": 16,
                        "name": "Shahidnagar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 187,
                        "district_id": 16,
                        "name": "Sabaila municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 188,
                        "district_id": 16,
                        "name": "Hanspur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 17,
                  "province_id": 2,
                  "name": "Mahhotari",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 189,
                        "district_id": 17,
                        "name": "Ekdara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 190,
                        "district_id": 17,
                        "name": "Aurahi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 191,
                        "district_id": 17,
                        "name": "Gaushala Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 192,
                        "district_id": 17,
                        "name": "Jaleshwor Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 193,
                        "district_id": 17,
                        "name": "Pipara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 194,
                        "district_id": 17,
                        "name": "Bardibas Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 195,
                        "district_id": 17,
                        "name": "Balwa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 196,
                        "district_id": 17,
                        "name": "Bhangha village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 197,
                        "district_id": 17,
                        "name": "Mathihani village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 198,
                        "district_id": 17,
                        "name": "Manra village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 199,
                        "district_id": 17,
                        "name": "Mahottari Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 200,
                        "district_id": 17,
                        "name": "Ramgopalpur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 201,
                        "district_id": 17,
                        "name": "Loharpati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 202,
                        "district_id": 17,
                        "name": "Samsi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 203,
                        "district_id": 17,
                        "name": "Sonama Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 18,
                  "province_id": 2,
                  "name": "Parsha",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 252,
                        "district_id": 18,
                        "name": "Chhipharmai Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 253,
                        "district_id": 18,
                        "name": "Jagarnathapur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 254,
                        "district_id": 18,
                        "name": "Dhobini village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 255,
                        "district_id": 18,
                        "name": "Paqha Manpur Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 256,
                        "district_id": 18,
                        "name": "Pattarawa Suguli village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 257,
                        "district_id": 18,
                        "name": "Parsakadhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 258,
                        "district_id": 18,
                        "name": "Pokhariya Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 259,
                        "district_id": 18,
                        "name": "Bahudarmai Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 260,
                        "district_id": 18,
                        "name": "Bindabasini village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 261,
                        "district_id": 18,
                        "name": "Birgunj Metropolitan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 262,
                        "district_id": 18,
                        "name": "Belwa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 263,
                        "district_id": 18,
                        "name": "Sakhuwa Parsauni Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 264,
                        "district_id": 18,
                        "name": "Suvronpur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 19,
                  "province_id": 2,
                  "name": "Rauthat",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 221,
                        "district_id": 19,
                        "name": "Ishnath village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 222,
                        "district_id": 19,
                        "name": "Kathariya Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 223,
                        "district_id": 19,
                        "name": "Garhamai village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 224,
                        "district_id": 19,
                        "name": "Garuda Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 225,
                        "district_id": 19,
                        "name": "Gujara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 226,
                        "district_id": 19,
                        "name": "Gaur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 227,
                        "district_id": 19,
                        "name": "Chandrapur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 228,
                        "district_id": 19,
                        "name": "Durga Bhagwati village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 229,
                        "district_id": 19,
                        "name": "Devahi Gonahi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 230,
                        "district_id": 19,
                        "name": "Paroha village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 231,
                        "district_id": 19,
                        "name": "Phatuwabijayapur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 232,
                        "district_id": 19,
                        "name": "Birindaban village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 233,
                        "district_id": 19,
                        "name": "Buddhimai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 234,
                        "district_id": 19,
                        "name": "Madhav Narayan village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 235,
                        "district_id": 19,
                        "name": "Mulapur village village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 236,
                        "district_id": 19,
                        "name": "Rajpur Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 20,
                  "province_id": 2,
                  "name": "Saptari",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 138,
                        "district_id": 20,
                        "name": "Kanchanupar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 139,
                        "district_id": 20,
                        "name": "Krishnaswaran village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 140,
                        "district_id": 20,
                        "name": "Khadak Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 141,
                        "district_id": 20,
                        "name": "chhinmasta Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 142,
                        "district_id": 20,
                        "name": "Dakneswori Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 143,
                        "district_id": 20,
                        "name": "Tirhut village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 144,
                        "district_id": 20,
                        "name": "Tilathi Koiladi Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 145,
                        "district_id": 20,
                        "name": "Bishnupur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 146,
                        "district_id": 20,
                        "name": "Belhi Chapena Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 147,
                        "district_id": 20,
                        "name": "Boderbasin Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 148,
                        "district_id": 20,
                        "name": "Mahadeva Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 149,
                        "district_id": 20,
                        "name": "Rajviraj Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 150,
                        "district_id": 20,
                        "name": "Rupani Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 151,
                        "district_id": 20,
                        "name": "Shambhunath Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 152,
                        "district_id": 20,
                        "name": "Saptakashi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 153,
                        "district_id": 20,
                        "name": "Surunga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 154,
                        "district_id": 20,
                        "name": "Hanumannagar Kankalini municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 21,
                  "province_id": 2,
                  "name": "Sarlahi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 204,
                        "district_id": 21,
                        "name": "Ishwarpur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 205,
                        "district_id": 21,
                        "name": "Kabilasi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 206,
                        "district_id": 21,
                        "name": "Godaita Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 207,
                        "district_id": 21,
                        "name": "Chakraghatta village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 208,
                        "district_id": 21,
                        "name": "Chandranagar village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 209,
                        "district_id": 21,
                        "name": "Dhanakul village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 210,
                        "district_id": 21,
                        "name": "Barthatwa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 211,
                        "district_id": 21,
                        "name": "Balra Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 212,
                        "district_id": 21,
                        "name": "Bagmati Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 213,
                        "district_id": 21,
                        "name": "Brahmapuri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 214,
                        "district_id": 21,
                        "name": "Malangwa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 215,
                        "district_id": 21,
                        "name": "Ramnagar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 216,
                        "district_id": 21,
                        "name": "Lalbandi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 217,
                        "district_id": 21,
                        "name": "Vishnu village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 218,
                        "district_id": 21,
                        "name": "Haripur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 219,
                        "district_id": 21,
                        "name": "Haripurwa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 220,
                        "district_id": 21,
                        "name": "Hariwan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 22,
                  "province_id": 2,
                  "name": "Siraha",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 155,
                        "district_id": 22,
                        "name": "Aarnama Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 156,
                        "district_id": 22,
                        "name": "Aaurahi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 157,
                        "district_id": 22,
                        "name": "Karganha village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 158,
                        "district_id": 22,
                        "name": "Kalyanpur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 159,
                        "district_id": 22,
                        "name": "Golbazar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 160,
                        "district_id": 22,
                        "name": "Dhangadhi Mai Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 161,
                        "district_id": 22,
                        "name": "Narha Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 162,
                        "district_id": 22,
                        "name": "Nebrajpur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 163,
                        "district_id": 22,
                        "name": "Bariyarpatti village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 164,
                        "district_id": 22,
                        "name": "Bhagwanpur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 165,
                        "district_id": 22,
                        "name": "Mirchhaiya Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 166,
                        "district_id": 22,
                        "name": "Laxmiipur Patari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 167,
                        "district_id": 22,
                        "name": "Lahan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 168,
                        "district_id": 22,
                        "name": "Vishnupur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 169,
                        "district_id": 22,
                        "name": "Saskhuwanankarkatti Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 170,
                        "district_id": 22,
                        "name": "Siraha Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 171,
                        "district_id": 22,
                        "name": "Sukhipur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               }
            ]
         },
         {
            "id": 3,
            "country_id": 1,
            "name": "State 3",
            "is_active": 1,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "districts": [
               {
                  "id": 23,
                  "province_id": 3,
                  "name": "Bhaktapur",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 332,
                        "district_id": 23,
                        "name": "Changunarayan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 333,
                        "district_id": 23,
                        "name": "Bhaktapur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 334,
                        "district_id": 23,
                        "name": "Madhpur Thimi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 335,
                        "district_id": 23,
                        "name": "Surya Vinayak Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 24,
                  "province_id": 3,
                  "name": "Chitwan",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 377,
                        "district_id": 24,
                        "name": "Ichhakamana village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 378,
                        "district_id": 24,
                        "name": "Kakika Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 379,
                        "district_id": 24,
                        "name": "Khairahani Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 380,
                        "district_id": 24,
                        "name": "Bharatpur Metropolitan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 381,
                        "district_id": 24,
                        "name": "Madi Municipal",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 382,
                        "district_id": 24,
                        "name": "Ratnagar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 383,
                        "district_id": 24,
                        "name": "Rapti Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 25,
                  "province_id": 3,
                  "name": "Dhading",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 296,
                        "district_id": 25,
                        "name": "Khaniyabas village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 297,
                        "district_id": 25,
                        "name": "Gangajuna village village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 298,
                        "district_id": 25,
                        "name": "Gajari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 299,
                        "district_id": 25,
                        "name": "Galchhi Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 300,
                        "district_id": 25,
                        "name": "JwalaMukhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 301,
                        "district_id": 25,
                        "name": "Tripura Sunduri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 302,
                        "district_id": 25,
                        "name": "Thakre village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 303,
                        "district_id": 25,
                        "name": "Dhunibeshi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 304,
                        "district_id": 25,
                        "name": "Nilkantha Nagarpalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 305,
                        "district_id": 25,
                        "name": "Netravati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 306,
                        "district_id": 25,
                        "name": "Benighhat Roranga village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 307,
                        "district_id": 25,
                        "name": "Rubi Valley Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 308,
                        "district_id": 25,
                        "name": "Siddhlek village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 26,
                  "province_id": 3,
                  "name": "Dolkha",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 265,
                        "district_id": 26,
                        "name": "Kalinchok village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 266,
                        "district_id": 26,
                        "name": "Gaurikankar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 267,
                        "district_id": 26,
                        "name": "Jiri Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 268,
                        "district_id": 26,
                        "name": "Tamakoshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 269,
                        "district_id": 26,
                        "name": "Bhimeshwar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 270,
                        "district_id": 26,
                        "name": "Melung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 271,
                        "district_id": 26,
                        "name": "Vigu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 272,
                        "district_id": 26,
                        "name": "Vatswar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 273,
                        "district_id": 26,
                        "name": "Shailung Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 27,
                  "province_id": 3,
                  "name": "Kathmandu",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 321,
                        "district_id": 27,
                        "name": "Kakeshwari Manohara Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 322,
                        "district_id": 27,
                        "name": "Kathmandu Metropolitan City",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 323,
                        "district_id": 27,
                        "name": "Kirtipur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 324,
                        "district_id": 27,
                        "name": "Gokarneshwar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 325,
                        "district_id": 27,
                        "name": "Chandragiri Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 326,
                        "district_id": 27,
                        "name": "Tokha Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 327,
                        "district_id": 27,
                        "name": "Tarakeshwar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 328,
                        "district_id": 27,
                        "name": "Dachhinkali municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 329,
                        "district_id": 27,
                        "name": "Nagarjuna Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 330,
                        "district_id": 27,
                        "name": "BudhaNilkantha Municiplity",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 331,
                        "district_id": 27,
                        "name": "Shankarapur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 28,
                  "province_id": 3,
                  "name": "Kavrepalanchok",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 342,
                        "district_id": 28,
                        "name": "Khanikhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 343,
                        "district_id": 28,
                        "name": "Chourideurali village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 344,
                        "district_id": 28,
                        "name": "Temal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 345,
                        "district_id": 28,
                        "name": "Dhulikhel Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 346,
                        "district_id": 28,
                        "name": "Namobhuddha Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 347,
                        "district_id": 28,
                        "name": "Panauti Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 348,
                        "district_id": 28,
                        "name": "panchkhal municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 349,
                        "district_id": 28,
                        "name": "Banepa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 350,
                        "district_id": 28,
                        "name": "Bethanchock village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 351,
                        "district_id": 28,
                        "name": "Bhumlu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 352,
                        "district_id": 28,
                        "name": "Mandandeeupur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 353,
                        "district_id": 28,
                        "name": "Mahabharata Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 354,
                        "district_id": 28,
                        "name": "Roshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 29,
                  "province_id": 3,
                  "name": "Lalitpur",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 336,
                        "district_id": 29,
                        "name": "Konganosom Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 337,
                        "district_id": 29,
                        "name": "Godavari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 338,
                        "district_id": 29,
                        "name": "Bagmati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 339,
                        "district_id": 29,
                        "name": "Mahakal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 340,
                        "district_id": 29,
                        "name": "Mahalakshmi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 341,
                        "district_id": 29,
                        "name": "Lalitpur Metropolitan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 30,
                  "province_id": 3,
                  "name": "Makwanpur",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 367,
                        "district_id": 30,
                        "name": "Indra Sarovar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 368,
                        "district_id": 30,
                        "name": "Kailash Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 369,
                        "district_id": 30,
                        "name": "Thaha municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 370,
                        "district_id": 30,
                        "name": "Bakaiya village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 371,
                        "district_id": 30,
                        "name": "Bagmati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 372,
                        "district_id": 30,
                        "name": "Bhimpedi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 373,
                        "district_id": 30,
                        "name": "MakwanpurGadhi Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 374,
                        "district_id": 30,
                        "name": "Manhari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 375,
                        "district_id": 30,
                        "name": "Raksirang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 376,
                        "district_id": 30,
                        "name": "Hetauda subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 31,
                  "province_id": 3,
                  "name": "Nuwakot",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 309,
                        "district_id": 31,
                        "name": "Kakani village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 310,
                        "district_id": 31,
                        "name": "Kispang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 311,
                        "district_id": 31,
                        "name": "Tadi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 312,
                        "district_id": 31,
                        "name": "Tarkeswor village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 313,
                        "district_id": 31,
                        "name": "Dupcheswor village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 314,
                        "district_id": 31,
                        "name": "Panchkanya village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 315,
                        "district_id": 31,
                        "name": "Balkotadhi municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 316,
                        "district_id": 31,
                        "name": "Meghang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 317,
                        "district_id": 31,
                        "name": "Likhu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 318,
                        "district_id": 31,
                        "name": "Bidur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 319,
                        "district_id": 31,
                        "name": "Shivpuri village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 320,
                        "district_id": 31,
                        "name": "Surya Gadhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 32,
                  "province_id": 3,
                  "name": "Ramechhap",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 274,
                        "district_id": 32,
                        "name": "Umakund village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 275,
                        "district_id": 32,
                        "name": "Khandadevi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 276,
                        "district_id": 32,
                        "name": "Gokulanga village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 277,
                        "district_id": 32,
                        "name": "Doramba Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 278,
                        "district_id": 32,
                        "name": "Mantali Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 279,
                        "district_id": 32,
                        "name": "Ramheep Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 280,
                        "district_id": 32,
                        "name": "Likhu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 281,
                        "district_id": 32,
                        "name": "Sunapati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 33,
                  "province_id": 3,
                  "name": "Rasuwa",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 291,
                        "district_id": 33,
                        "name": "Uttagaya village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 292,
                        "district_id": 33,
                        "name": "Kalika villagepalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 293,
                        "district_id": 33,
                        "name": "Gosai Kund village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 294,
                        "district_id": 33,
                        "name": "Nukkund village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 295,
                        "district_id": 33,
                        "name": "Parwatikund Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 34,
                  "province_id": 3,
                  "name": "Sindhuli",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 282,
                        "district_id": 34,
                        "name": "Kamalmai Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 283,
                        "district_id": 34,
                        "name": "Golanjor village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 284,
                        "district_id": 34,
                        "name": "Ghayanglekh Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 285,
                        "district_id": 34,
                        "name": "Tinpatan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 286,
                        "district_id": 34,
                        "name": "Dudhouli Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 287,
                        "district_id": 34,
                        "name": "phikkal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 288,
                        "district_id": 34,
                        "name": "Marin Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 289,
                        "district_id": 34,
                        "name": "Sunkoshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 290,
                        "district_id": 34,
                        "name": "HariharpurGadhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 35,
                  "province_id": 3,
                  "name": "Sindhupalchok",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 355,
                        "district_id": 35,
                        "name": "Chotara Municipality of Sagachkot",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 356,
                        "district_id": 35,
                        "name": "Jugal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 357,
                        "district_id": 35,
                        "name": "Tripura Sunduri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 358,
                        "district_id": 35,
                        "name": "Panchpokhari Thangalpal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 359,
                        "district_id": 35,
                        "name": "Balefi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 360,
                        "district_id": 35,
                        "name": "Bhotekoshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 361,
                        "district_id": 35,
                        "name": "Melamchi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 362,
                        "district_id": 35,
                        "name": "Indravati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 363,
                        "district_id": 35,
                        "name": "Lisankhu pakhar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 364,
                        "district_id": 35,
                        "name": "Barhbise Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 365,
                        "district_id": 35,
                        "name": "Sunkoshi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 366,
                        "district_id": 35,
                        "name": "Helambu Sangh",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               }
            ]
         },
         {
            "id": 4,
            "country_id": 1,
            "name": "State 4",
            "is_active": 1,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "districts": [
               {
                  "id": 36,
                  "province_id": 4,
                  "name": "Baglung",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 460,
                        "district_id": 36,
                        "name": "Kathekhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 461,
                        "district_id": 36,
                        "name": "Gulkot municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 462,
                        "district_id": 36,
                        "name": "Jaimuni municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 463,
                        "district_id": 36,
                        "name": "Dhorpatan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 464,
                        "district_id": 36,
                        "name": "Tanmankhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 465,
                        "district_id": 36,
                        "name": "Tarakhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 466,
                        "district_id": 36,
                        "name": "Nisikhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 467,
                        "district_id": 36,
                        "name": "Baglung municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 468,
                        "district_id": 36,
                        "name": "Badigad village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 469,
                        "district_id": 36,
                        "name": "Barreng Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 37,
                  "province_id": 4,
                  "name": "Gorkha",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 384,
                        "district_id": 37,
                        "name": "Azirkot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 385,
                        "district_id": 37,
                        "name": "Auraghat village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 386,
                        "district_id": 37,
                        "name": "Gandhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 387,
                        "district_id": 37,
                        "name": "Gorkha Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 388,
                        "district_id": 37,
                        "name": "Chum Nubbi Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 389,
                        "district_id": 37,
                        "name": "Thache village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 390,
                        "district_id": 37,
                        "name": "Palungtar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 391,
                        "district_id": 37,
                        "name": "Bhimasen village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 392,
                        "district_id": 37,
                        "name": "Shahid Lakhan villagepalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 393,
                        "district_id": 37,
                        "name": "Srinachok village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 394,
                        "district_id": 37,
                        "name": "Sulikot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 38,
                  "province_id": 4,
                  "name": "Kaski",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 407,
                        "district_id": 38,
                        "name": "Annapurna village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 408,
                        "district_id": 38,
                        "name": "Pokhara Leknath Metropolitan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 409,
                        "district_id": 38,
                        "name": "Machhapuchhre village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 410,
                        "district_id": 38,
                        "name": "Madi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 411,
                        "district_id": 38,
                        "name": "Rupa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 39,
                  "province_id": 4,
                  "name": "Lamjung",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 399,
                        "district_id": 39,
                        "name": "Kualasothar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 400,
                        "district_id": 39,
                        "name": "Dudhpokhari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 401,
                        "district_id": 39,
                        "name": "Durdi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 402,
                        "district_id": 39,
                        "name": "Baseshahar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 403,
                        "district_id": 39,
                        "name": "MadhyaNepal municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 404,
                        "district_id": 39,
                        "name": "Mersyangdi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 405,
                        "district_id": 39,
                        "name": "Rainash Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 406,
                        "district_id": 39,
                        "name": "Sundarbazar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 40,
                  "province_id": 4,
                  "name": "Manag",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 395,
                        "district_id": 40,
                        "name": "chame village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 396,
                        "district_id": 40,
                        "name": "Narafu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 397,
                        "district_id": 40,
                        "name": "Nashang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 398,
                        "district_id": 40,
                        "name": "Nasyang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 41,
                  "province_id": 4,
                  "name": "Mustang",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 449,
                        "district_id": 41,
                        "name": "Gharpojhong village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 450,
                        "district_id": 41,
                        "name": "Thasang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 451,
                        "district_id": 41,
                        "name": "Dolame village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 452,
                        "district_id": 41,
                        "name": "bramhagau muktichhetra village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 453,
                        "district_id": 41,
                        "name": "Lomanthang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 42,
                  "province_id": 4,
                  "name": "Myagdi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 454,
                        "district_id": 42,
                        "name": "Annapurna village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 455,
                        "district_id": 42,
                        "name": "Dhawalagiri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 456,
                        "district_id": 42,
                        "name": "Beni Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 457,
                        "district_id": 42,
                        "name": "Mangala Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 458,
                        "district_id": 42,
                        "name": "Malika village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 459,
                        "district_id": 42,
                        "name": "Raghuganga villagepalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 43,
                  "province_id": 4,
                  "name": "Nawalparashi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 433,
                        "district_id": 43,
                        "name": "Kawasoti Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 435,
                        "district_id": 43,
                        "name": "Gaidakot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 437,
                        "district_id": 43,
                        "name": "Devchuli Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 439,
                        "district_id": 43,
                        "name": "Bungdikali village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 441,
                        "district_id": 43,
                        "name": "Bullingtar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 443,
                        "district_id": 43,
                        "name": "Madhyabindu Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 445,
                        "district_id": 43,
                        "name": "Vinaya Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 447,
                        "district_id": 43,
                        "name": "Hupsquate Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 505,
                        "district_id": 43,
                        "name": "Palhinandan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 507,
                        "district_id": 43,
                        "name": "Pratappur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 509,
                        "district_id": 43,
                        "name": "Bardaghat Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 511,
                        "district_id": 43,
                        "name": "Ramgram Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 513,
                        "district_id": 43,
                        "name": "Sarawal Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 515,
                        "district_id": 43,
                        "name": "Sunwal Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 517,
                        "district_id": 43,
                        "name": "Susta village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 44,
                  "province_id": 4,
                  "name": "Parbat",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 470,
                        "district_id": 44,
                        "name": "Kushma Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 471,
                        "district_id": 44,
                        "name": "Jalajala village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 472,
                        "district_id": 44,
                        "name": "Paiyu Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 473,
                        "district_id": 44,
                        "name": "Phalebas Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 474,
                        "district_id": 44,
                        "name": "Mahashila villagepalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 475,
                        "district_id": 44,
                        "name": "Modi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 476,
                        "district_id": 44,
                        "name": "Bihadi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 45,
                  "province_id": 4,
                  "name": "Syangja",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 422,
                        "district_id": 45,
                        "name": "Arjun Chowpari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 423,
                        "district_id": 45,
                        "name": "Aldhkhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 424,
                        "district_id": 45,
                        "name": "Kaligandaki village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 425,
                        "district_id": 45,
                        "name": "Gallang Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 426,
                        "district_id": 45,
                        "name": "Chapkot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 427,
                        "district_id": 45,
                        "name": "Puttabazar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 428,
                        "district_id": 45,
                        "name": "Fedkhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 429,
                        "district_id": 45,
                        "name": "Bhirkkot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 430,
                        "district_id": 45,
                        "name": "Waling Municipal",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 431,
                        "district_id": 45,
                        "name": "Viruwa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 432,
                        "district_id": 45,
                        "name": "Harinas village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 46,
                  "province_id": 4,
                  "name": "Tanahu",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 412,
                        "district_id": 46,
                        "name": "Abukhaireni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 413,
                        "district_id": 46,
                        "name": "Rishang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 414,
                        "district_id": 46,
                        "name": "Ghiring village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 415,
                        "district_id": 46,
                        "name": "Devghat village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 416,
                        "district_id": 46,
                        "name": "Bandipur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 417,
                        "district_id": 46,
                        "name": "Bhanu Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 418,
                        "district_id": 46,
                        "name": "Bhimad Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 419,
                        "district_id": 46,
                        "name": "Magdie village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 420,
                        "district_id": 46,
                        "name": "Byas Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 421,
                        "district_id": 46,
                        "name": "Municipality of Shuklagandaki",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               }
            ]
         },
         {
            "id": 5,
            "country_id": 1,
            "name": "State 5",
            "is_active": 1,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "districts": [
               {
                  "id": 47,
                  "province_id": 5,
                  "name": "Arghakhachhi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 499,
                        "district_id": 47,
                        "name": "Chhatradev village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 500,
                        "district_id": 47,
                        "name": "Panini village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 501,
                        "district_id": 47,
                        "name": "Bhumikasthan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 502,
                        "district_id": 47,
                        "name": "Malarii village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 503,
                        "district_id": 47,
                        "name": "Sandhikharka municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 504,
                        "district_id": 47,
                        "name": "Sitganga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 48,
                  "province_id": 5,
                  "name": "Banke",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 580,
                        "district_id": 48,
                        "name": "Kohalpur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 581,
                        "district_id": 48,
                        "name": "Khajura village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 582,
                        "district_id": 48,
                        "name": "Janaki village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 583,
                        "district_id": 48,
                        "name": "Duduwa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 584,
                        "district_id": 48,
                        "name": "Narayanapur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 585,
                        "district_id": 48,
                        "name": "Nepalgunj subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 586,
                        "district_id": 48,
                        "name": "Baijnath village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 587,
                        "district_id": 48,
                        "name": "Rapti Sonari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 49,
                  "province_id": 5,
                  "name": "Bardiya",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 588,
                        "district_id": 49,
                        "name": "Gularia Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 589,
                        "district_id": 49,
                        "name": "Geruwa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 590,
                        "district_id": 49,
                        "name": "Thakurababa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 591,
                        "district_id": 49,
                        "name": "Badhaiyataal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 592,
                        "district_id": 49,
                        "name": "Barbardiya Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 593,
                        "district_id": 49,
                        "name": "Basagadhi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 594,
                        "district_id": 49,
                        "name": "Madhuban municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 595,
                        "district_id": 49,
                        "name": "Rajapur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 50,
                  "province_id": 5,
                  "name": "Dang",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 570,
                        "district_id": 50,
                        "name": "Garhwa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 571,
                        "district_id": 50,
                        "name": "Ghorahi Subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 572,
                        "district_id": 50,
                        "name": "Tulsipur subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 573,
                        "district_id": 50,
                        "name": "Dansisaran village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 574,
                        "district_id": 50,
                        "name": "Banglachuli village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 575,
                        "district_id": 50,
                        "name": "Babai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 576,
                        "district_id": 50,
                        "name": "Rajpur Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 577,
                        "district_id": 50,
                        "name": "Rapti village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 578,
                        "district_id": 50,
                        "name": "Lamahi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 579,
                        "district_id": 50,
                        "name": "Shantinagar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 51,
                  "province_id": 5,
                  "name": "Gulmi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 477,
                        "district_id": 51,
                        "name": "Isma village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 478,
                        "district_id": 51,
                        "name": "Kaligandaki village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 479,
                        "district_id": 51,
                        "name": "Guilmi Darwar Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 480,
                        "district_id": 51,
                        "name": "Chandrakot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 481,
                        "district_id": 51,
                        "name": "Chattrakot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 482,
                        "district_id": 51,
                        "name": "Dhurkot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 483,
                        "district_id": 51,
                        "name": "Medne village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 484,
                        "district_id": 51,
                        "name": "Malika village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 485,
                        "district_id": 51,
                        "name": "Mussikkot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 486,
                        "district_id": 51,
                        "name": "Ruru village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 487,
                        "district_id": 51,
                        "name": "Resuga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 488,
                        "district_id": 51,
                        "name": "Satyabati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 52,
                  "province_id": 5,
                  "name": "Kapilbastu",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 535,
                        "district_id": 52,
                        "name": "Kapilvastu Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 536,
                        "district_id": 52,
                        "name": "Krishnanagar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 537,
                        "district_id": 52,
                        "name": "Banganga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 538,
                        "district_id": 52,
                        "name": "Buddha Bhambhi municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 539,
                        "district_id": 52,
                        "name": "Maharajganj Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 540,
                        "district_id": 52,
                        "name": "Maya Devi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 541,
                        "district_id": 52,
                        "name": "Yasodhara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 542,
                        "district_id": 52,
                        "name": "Vijayanagar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 543,
                        "district_id": 52,
                        "name": "Shivraj Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 544,
                        "district_id": 52,
                        "name": "Suddodhan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 53,
                  "province_id": 5,
                  "name": "Nawalparashi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 434,
                        "district_id": 53,
                        "name": "Kawasoti Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 436,
                        "district_id": 53,
                        "name": "Gaidakot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 438,
                        "district_id": 53,
                        "name": "Devchuli Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 440,
                        "district_id": 53,
                        "name": "Bungdikali village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 442,
                        "district_id": 53,
                        "name": "Bullingtar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 444,
                        "district_id": 53,
                        "name": "Madhyabindu Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 446,
                        "district_id": 53,
                        "name": "Vinaya Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 448,
                        "district_id": 53,
                        "name": "Hupsquate Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 506,
                        "district_id": 53,
                        "name": "Palhinandan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 508,
                        "district_id": 53,
                        "name": "Pratappur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 510,
                        "district_id": 53,
                        "name": "Bardaghat Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 512,
                        "district_id": 53,
                        "name": "Ramgram Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 514,
                        "district_id": 53,
                        "name": "Sarawal Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 516,
                        "district_id": 53,
                        "name": "Sunwal Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 518,
                        "district_id": 53,
                        "name": "Susta village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 54,
                  "province_id": 5,
                  "name": "Palpa",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 489,
                        "district_id": 54,
                        "name": "Tansen Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 490,
                        "district_id": 54,
                        "name": "Tinu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 491,
                        "district_id": 54,
                        "name": "Nisdi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 492,
                        "district_id": 54,
                        "name": "PurwaKhola village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 493,
                        "district_id": 54,
                        "name": "Bagannaskali village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 494,
                        "district_id": 54,
                        "name": "Mathagadi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 495,
                        "district_id": 54,
                        "name": "Rambha Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 496,
                        "district_id": 54,
                        "name": "Rampur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 497,
                        "district_id": 54,
                        "name": "Ridikikot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 498,
                        "district_id": 54,
                        "name": "Rainadevi Chhahara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 55,
                  "province_id": 5,
                  "name": "Puthan",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 561,
                        "district_id": 55,
                        "name": "Aravati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 562,
                        "district_id": 55,
                        "name": "Gaumukhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 563,
                        "district_id": 55,
                        "name": "jhimaruk village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 564,
                        "district_id": 55,
                        "name": "Naubahini village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 565,
                        "district_id": 55,
                        "name": "Puthan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 566,
                        "district_id": 55,
                        "name": "Mallarani village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 567,
                        "district_id": 55,
                        "name": "Mandvi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 568,
                        "district_id": 55,
                        "name": "Sarumarani village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 569,
                        "district_id": 55,
                        "name": "Swargadwari municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 56,
                  "province_id": 5,
                  "name": "Rolpa",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 551,
                        "district_id": 56,
                        "name": "Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 552,
                        "district_id": 56,
                        "name": "Thawang village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 553,
                        "district_id": 56,
                        "name": "DuiKholi Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 554,
                        "district_id": 56,
                        "name": "Madi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 555,
                        "district_id": 56,
                        "name": "Runtigadhi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 556,
                        "district_id": 56,
                        "name": "Rolpa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 557,
                        "district_id": 56,
                        "name": "Lunggri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 558,
                        "district_id": 56,
                        "name": "Sukidaha village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 559,
                        "district_id": 56,
                        "name": "Sunchhahari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 560,
                        "district_id": 56,
                        "name": "Suvarnavati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 57,
                  "province_id": 5,
                  "name": "Rukum",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 545,
                        "district_id": 57,
                        "name": "Putha Uttaranga village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 547,
                        "district_id": 57,
                        "name": "Bhume village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 549,
                        "district_id": 57,
                        "name": "Sisne Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 596,
                        "district_id": 57,
                        "name": "Aathbiskot municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 598,
                        "district_id": 57,
                        "name": "Chaurajhaari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 600,
                        "district_id": 57,
                        "name": "Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 602,
                        "district_id": 57,
                        "name": "Baafikot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 604,
                        "district_id": 57,
                        "name": "Mussikkot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 606,
                        "district_id": 57,
                        "name": "Sanibheri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 58,
                  "province_id": 5,
                  "name": "Rupandehi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 519,
                        "district_id": 58,
                        "name": "Omsatiya village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 520,
                        "district_id": 58,
                        "name": "Kanchan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 521,
                        "district_id": 58,
                        "name": "Kotheahimai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 522,
                        "district_id": 58,
                        "name": "Gaiddhawa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 523,
                        "district_id": 58,
                        "name": "Tilotama municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 524,
                        "district_id": 58,
                        "name": "Devdah municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 525,
                        "district_id": 58,
                        "name": "Butwal Subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 526,
                        "district_id": 58,
                        "name": "Marchabaari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 527,
                        "district_id": 58,
                        "name": "Maya Devi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 528,
                        "district_id": 58,
                        "name": "Rohini village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 529,
                        "district_id": 58,
                        "name": "Lumbini cultural municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 530,
                        "district_id": 58,
                        "name": "Suddodhan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 531,
                        "district_id": 58,
                        "name": "Sammarimai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 532,
                        "district_id": 58,
                        "name": "Siddharthnagar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 533,
                        "district_id": 58,
                        "name": "Siyari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 534,
                        "district_id": 58,
                        "name": "Sainamaina Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               }
            ]
         },
         {
            "id": 6,
            "country_id": 1,
            "name": "State 6",
            "is_active": 1,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "districts": [
               {
                  "id": 59,
                  "province_id": 6,
                  "name": "Dailekh",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 661,
                        "district_id": 59,
                        "name": "Aathbish municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 662,
                        "district_id": 59,
                        "name": "Guras village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 663,
                        "district_id": 59,
                        "name": "Chamunda bindrasaini Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 664,
                        "district_id": 59,
                        "name": "Thatikandh village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 665,
                        "district_id": 59,
                        "name": "Dungeshwor Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 666,
                        "district_id": 59,
                        "name": "Dulu Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 667,
                        "district_id": 59,
                        "name": "Narayan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 668,
                        "district_id": 59,
                        "name": "Naumulle village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 669,
                        "district_id": 59,
                        "name": "Bhagwatimai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 670,
                        "district_id": 59,
                        "name": "Bhairavi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 671,
                        "district_id": 59,
                        "name": "Mahavu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 60,
                  "province_id": 6,
                  "name": "Dolpa",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 618,
                        "district_id": 60,
                        "name": "Kaike village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 619,
                        "district_id": 60,
                        "name": "Charka Tangsong Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 620,
                        "district_id": 60,
                        "name": "Jagadulla village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 621,
                        "district_id": 60,
                        "name": "Thuli BHeri Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 622,
                        "district_id": 60,
                        "name": "Dolppo Buddha Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 623,
                        "district_id": 60,
                        "name": "Tripura Sunduri municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 624,
                        "district_id": 60,
                        "name": "Mudakechula village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 625,
                        "district_id": 60,
                        "name": "Shefoksundu Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 61,
                  "province_id": 6,
                  "name": "Humla",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 647,
                        "district_id": 61,
                        "name": "Adanchuli village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 648,
                        "district_id": 61,
                        "name": "Kharpunath village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 649,
                        "district_id": 61,
                        "name": "Chankheli village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 650,
                        "district_id": 61,
                        "name": "Tajakot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 651,
                        "district_id": 61,
                        "name": "Namkha village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 652,
                        "district_id": 61,
                        "name": "Sarkegaad village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 653,
                        "district_id": 61,
                        "name": "Simkot Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 62,
                  "province_id": 6,
                  "name": "Jagarkot",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 654,
                        "district_id": 62,
                        "name": "Kusse village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 655,
                        "district_id": 62,
                        "name": "chhedagad Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 656,
                        "district_id": 62,
                        "name": "Junnichande Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 657,
                        "district_id": 62,
                        "name": "Triveni Nalgaad Municipal",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 658,
                        "district_id": 62,
                        "name": "Baarekot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 659,
                        "district_id": 62,
                        "name": "Bheri Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 660,
                        "district_id": 62,
                        "name": "Shivalaya Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 63,
                  "province_id": 6,
                  "name": "Jumla",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 630,
                        "district_id": 63,
                        "name": "Kankasunduri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 631,
                        "district_id": 63,
                        "name": "Guthichaur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 632,
                        "district_id": 63,
                        "name": "Chandan Nath Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 633,
                        "district_id": 63,
                        "name": "Tatopani village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 634,
                        "district_id": 63,
                        "name": "Tilla village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 635,
                        "district_id": 63,
                        "name": "Patarasi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 636,
                        "district_id": 63,
                        "name": "Sinja Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 637,
                        "district_id": 63,
                        "name": "Hima village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 64,
                  "province_id": 6,
                  "name": "Kalikot",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 638,
                        "district_id": 64,
                        "name": "Kalika villagepalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 639,
                        "district_id": 64,
                        "name": "Khadachakra Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 640,
                        "district_id": 64,
                        "name": "Tilagufa Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 641,
                        "district_id": 64,
                        "name": "Naraharinath village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 642,
                        "district_id": 64,
                        "name": "Panchaljharna village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 643,
                        "district_id": 64,
                        "name": "Palata Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 644,
                        "district_id": 64,
                        "name": "Mahabai village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 645,
                        "district_id": 64,
                        "name": "Rasscot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 646,
                        "district_id": 64,
                        "name": "Sani Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 65,
                  "province_id": 6,
                  "name": "Mugu",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 626,
                        "district_id": 65,
                        "name": "Khatyad village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 627,
                        "district_id": 65,
                        "name": "Chhayanath Rara Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 628,
                        "district_id": 65,
                        "name": "Mugum karmarong Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 629,
                        "district_id": 65,
                        "name": "Souru village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 66,
                  "province_id": 6,
                  "name": "Rukum",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 546,
                        "district_id": 66,
                        "name": "Putha Uttaranga village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 548,
                        "district_id": 66,
                        "name": "Bhume village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 550,
                        "district_id": 66,
                        "name": "Sisne Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 597,
                        "district_id": 66,
                        "name": "Aathbiskot municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 599,
                        "district_id": 66,
                        "name": "Chaurajhaari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 601,
                        "district_id": 66,
                        "name": "Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 603,
                        "district_id": 66,
                        "name": "Baafikot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 605,
                        "district_id": 66,
                        "name": "Mussikkot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 607,
                        "district_id": 66,
                        "name": "Sanibheri village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 67,
                  "province_id": 6,
                  "name": "Salyan",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 608,
                        "district_id": 67,
                        "name": "Kapurkot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 609,
                        "district_id": 67,
                        "name": "Kalmati village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 610,
                        "district_id": 67,
                        "name": "Kumakhmallika village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 611,
                        "district_id": 67,
                        "name": "Chattreshwari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 612,
                        "district_id": 67,
                        "name": "Dhorchaur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 613,
                        "district_id": 67,
                        "name": "Triveni village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 614,
                        "district_id": 67,
                        "name": "Darma village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 615,
                        "district_id": 67,
                        "name": "Bangad Kupinde Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 616,
                        "district_id": 67,
                        "name": "Bagchaur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 617,
                        "district_id": 67,
                        "name": "Sharda Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 68,
                  "province_id": 6,
                  "name": "Surkhet",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 672,
                        "district_id": 68,
                        "name": "Gurbhakot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 673,
                        "district_id": 68,
                        "name": "chingad village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 674,
                        "district_id": 68,
                        "name": "Chaukune village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 675,
                        "district_id": 68,
                        "name": "Panchpuri Municipalities",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 676,
                        "district_id": 68,
                        "name": "Barahtal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 677,
                        "district_id": 68,
                        "name": "Birendranagar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 678,
                        "district_id": 68,
                        "name": "Veriganga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 679,
                        "district_id": 68,
                        "name": "Lekbeshi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 680,
                        "district_id": 68,
                        "name": "Simta Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               }
            ]
         },
         {
            "id": 7,
            "country_id": 1,
            "name": "State 7",
            "is_active": 1,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "districts": [
               {
                  "id": 69,
                  "province_id": 7,
                  "name": "Achham",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 690,
                        "district_id": 69,
                        "name": "Kamalbazar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 691,
                        "district_id": 69,
                        "name": "Chaurapati village council",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 692,
                        "district_id": 69,
                        "name": "Dhakari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 693,
                        "district_id": 69,
                        "name": "Tarmakhad village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 694,
                        "district_id": 69,
                        "name": "Panchdeval Vinayak Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 695,
                        "district_id": 69,
                        "name": "Banigadhi Jayigadh village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 696,
                        "district_id": 69,
                        "name": "Mangalsen Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 697,
                        "district_id": 69,
                        "name": "Mallekh village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 698,
                        "district_id": 69,
                        "name": "Ramaroshan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 699,
                        "district_id": 69,
                        "name": "Safebbagar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 70,
                  "province_id": 7,
                  "name": "Baitadi",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 743,
                        "district_id": 70,
                        "name": "Dillasaini village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 744,
                        "district_id": 70,
                        "name": "Dasherthchand Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 745,
                        "district_id": 70,
                        "name": "Dogadakedar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 746,
                        "district_id": 70,
                        "name": "Pancheshwar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 747,
                        "district_id": 70,
                        "name": "Patan Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 748,
                        "district_id": 70,
                        "name": "Purchaudi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 749,
                        "district_id": 70,
                        "name": "Mallaudi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 750,
                        "district_id": 70,
                        "name": "Shivnath villagepalika",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 751,
                        "district_id": 70,
                        "name": "Sigas village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 752,
                        "district_id": 70,
                        "name": "Surnaya village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 71,
                  "province_id": 7,
                  "name": "Bajhang",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 700,
                        "district_id": 71,
                        "name": "Kadda village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 701,
                        "district_id": 71,
                        "name": "Kedarsainyu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 702,
                        "district_id": 71,
                        "name": "Khaptaddchhana village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 703,
                        "district_id": 71,
                        "name": "Chhabispathibhera Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 704,
                        "district_id": 71,
                        "name": "Jai Prithavi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 705,
                        "district_id": 71,
                        "name": "Talkot village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 706,
                        "district_id": 71,
                        "name": "Thalara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 707,
                        "district_id": 71,
                        "name": "Durgathali village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 708,
                        "district_id": 71,
                        "name": "Bungulmunicipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 709,
                        "district_id": 71,
                        "name": "Masta village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 710,
                        "district_id": 71,
                        "name": "Bittathadachir village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 711,
                        "district_id": 71,
                        "name": "Surma Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 72,
                  "province_id": 7,
                  "name": "Bajura",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 681,
                        "district_id": 72,
                        "name": "Gaumul Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 682,
                        "district_id": 72,
                        "name": "chhededah village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 683,
                        "district_id": 72,
                        "name": "Triveni Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 684,
                        "district_id": 72,
                        "name": "Pandabgufa village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 685,
                        "district_id": 72,
                        "name": "Budimalika Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 686,
                        "district_id": 72,
                        "name": "Budhiganga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 687,
                        "district_id": 72,
                        "name": "BudhiNanda municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 688,
                        "district_id": 72,
                        "name": "Swamikartik village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 689,
                        "district_id": 72,
                        "name": "Himali village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 73,
                  "province_id": 7,
                  "name": "Dadeldhura",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 753,
                        "district_id": 73,
                        "name": "Ajaymeru village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 754,
                        "district_id": 73,
                        "name": "Amaragadi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 755,
                        "district_id": 73,
                        "name": "Alital Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 756,
                        "district_id": 73,
                        "name": "Ganapaphudhara village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 757,
                        "district_id": 73,
                        "name": "Navdurgarga village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 758,
                        "district_id": 73,
                        "name": "Parshuram Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 759,
                        "district_id": 73,
                        "name": "Bhageshwar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 74,
                  "province_id": 7,
                  "name": "Darchula",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 734,
                        "district_id": 74,
                        "name": "AapiHimal Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 735,
                        "district_id": 74,
                        "name": "Duhu village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 736,
                        "district_id": 74,
                        "name": "Naugad village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 737,
                        "district_id": 74,
                        "name": "Byass village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 738,
                        "district_id": 74,
                        "name": "Mahakali Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 739,
                        "district_id": 74,
                        "name": "Marma village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 740,
                        "district_id": 74,
                        "name": "MallikaArjun village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 741,
                        "district_id": 74,
                        "name": "Lekam Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 742,
                        "district_id": 74,
                        "name": "Shailyashikhar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 75,
                  "province_id": 7,
                  "name": "Doti",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 712,
                        "district_id": 75,
                        "name": "Aadarsha village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 713,
                        "district_id": 75,
                        "name": "K.C. Singh The village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 714,
                        "district_id": 75,
                        "name": "Jorayal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 715,
                        "district_id": 75,
                        "name": "Dipayalsilgadhi Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 716,
                        "district_id": 75,
                        "name": "PurwaChowki village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 717,
                        "district_id": 75,
                        "name": "Badikedar village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 718,
                        "district_id": 75,
                        "name": "Bogtan village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 719,
                        "district_id": 75,
                        "name": "Shikhar Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 720,
                        "district_id": 75,
                        "name": "Sayal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 76,
                  "province_id": 7,
                  "name": "Kailali",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 721,
                        "district_id": 76,
                        "name": "Kailari village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 722,
                        "district_id": 76,
                        "name": "Godavari Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 723,
                        "district_id": 76,
                        "name": "Gauriganga Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 724,
                        "district_id": 76,
                        "name": "Ghodaghodi municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 725,
                        "district_id": 76,
                        "name": "Chure village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 726,
                        "district_id": 76,
                        "name": "Janaki village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 727,
                        "district_id": 76,
                        "name": "Joshipur village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 728,
                        "district_id": 76,
                        "name": "Tikapur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 729,
                        "district_id": 76,
                        "name": "Dhanghadi Subcontinent",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 730,
                        "district_id": 76,
                        "name": "Bardagoriya Village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 731,
                        "district_id": 76,
                        "name": "Bhajni Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 732,
                        "district_id": 76,
                        "name": "Mohanyal village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 733,
                        "district_id": 76,
                        "name": "Lamkichuha Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               },
               {
                  "id": 77,
                  "province_id": 7,
                  "name": "Kanchanpur",
                  "is_active": 1,
                  "created_at": null,
                  "updated_at": null,
                  "deleted_at": null,
                  "vdcmuns": [
                     {
                        "id": 760,
                        "district_id": 77,
                        "name": "Krishnapur Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 761,
                        "district_id": 77,
                        "name": "Punarbass municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 762,
                        "district_id": 77,
                        "name": "Beldadi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 763,
                        "district_id": 77,
                        "name": "Belauri Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 764,
                        "district_id": 77,
                        "name": "Bhimdutt Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 765,
                        "district_id": 77,
                        "name": "Mahakali Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 766,
                        "district_id": 77,
                        "name": "Laljhadi village",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 767,
                        "district_id": 77,
                        "name": "Vedkot Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     },
                     {
                        "id": 768,
                        "district_id": 77,
                        "name": "Suklaphanta Municipality",
                        "is_active": 1,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                     }
                  ]
               }
            ]
         }
      ]
   }
]