import React, { useEffect } from 'react';
import './Page.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Section from '../../Components/Section/Section';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import { getImageUrl } from '../../utils/ImageUrl';
import { baseUrl } from '../../api/api';

const Page = props => {

    const [loading, setLoading] = React.useState(true);
    const [pageData, setPageData] = React.useState({});
    const fetchData = async () => {
        setLoading(true)

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        await fetch(`${baseUrl}/pages?_where[0][slug]=${props.match.params.slug}`, requestOptions)
            .then((response) => response.json())
            .then((result) => setPageData(result?.[0] || undefined))
            .catch((error) => console.error(error));
        setLoading(false)

    }

    useEffect(() => {
        fetchData()
    }, [props.match.params.slug])


    return (
        <React.Fragment>
            <div className="blog-page">
                {!loading && pageData &&
                    <div>
                        <div className='page-title'>
                            <h1 style={{ color: "white", textAlign: "center", padding: "40px 40px" }}>{pageData?.title}</h1>
                        </div>
                        <Container className="blog-wrapper">
                            <Row className="mb-5">

                                <Col lg={12}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: pageData.content ? pageData.content : ''
                                        }}
                                    />


                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
                <Row className="my-5 justify-content-center">
                    {loading &&
                        <Loading />
                    }

                    {!loading && !pageData &&
                        <EmptyHolder text="Page not found" />
                    }
                </Row>
            </div>
        </React.Fragment>
    )
}

export default Page;